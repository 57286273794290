<!-- eslint-disable -->
<template>
  <div id="contacts">
    <good-table-advance-search
      :rows="rows"
      :columns="columns"
      :columnState="columnState"
    />
  </div>
</template>

<script>
import GoodTableAdvanceSearch from "@/views/used-tables/GoodTableAdvanceSearch.vue";
import { VBToggle } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    GoodTableAdvanceSearch,
  },
  provide: {
    link: "contacts",
    filterName: "apps-todo-contacts-filter",
    tagName: "apps-todo-contacts-tag",
    appName: "apps-todo-contacts",
    emailFilterName: "apps-email-contacts-folder",
    labelName: "apps-email-contacts-label",
    appEmailName: "apps-email-contacts",
  },
  data() {
    return {
      columns: [
        {
          label: "Name",
          field: "fullName",
          hidden: false,
        },
        {
          label: "Email",
          field: "email",
          hidden: false,
        },
        {
          label: "Phone",
          field: "phone",
          hidden: false,
        },
        {
          label: "Stage",
          field: "stage",
          hidden: false,
        },
        {
          label: "Status",
          field: "status",
          hidden: false,
        },
        {
          label: "Assigned To",
          field: "assignedTo",
          hidden: false,
        },
        {
          label: "Source",
          field: "source",
          hidden: false,
        },
        {
          label: "Page",
          field: "page",
          hidden: false,
        },
        {
          label: "Campaign",
          field: "campaign",
          hidden: true,
        },
        {
          label: "Last Activity",
          field: "lastActivity",
          hidden: true,
        },
        {
          label: "Follow Up",
          field: "followUp",
          hidden: true,
        },
        {
          label: "City",
          field: "city",
          hidden: true,
        },
        {
          label: "Country",
          field: "country",
          hidden: true,
        },
        {
          label: "Lead Age",
          field: "leadAge",
          hidden: true,
        },
        {
          label: "Converted at",
          field: "convertedAt",
          hidden: false,
        },
        {
          label: "Converted by",
          field: "convertedBy",
          hidden: false,
        },
      ],
      rows: [],
      updatedColsContacts: [],
    };
  },
  mounted() {
    // eslint-disable-next-line operator-linebreak
    this.columns =
      JSON.parse(localStorage.getItem("updatedColsContacts")) || this.columns;

    // eslint-disable-next-line operator-linebreak
    this.rows = JSON.parse(localStorage.getItem("contactsRows")) || this.rows;

    this.$router.push("/contacts").catch(() => {});
  },
  methods: {
    columnState() {
      this.updatedColsContacts = [];
      this.columns.forEach((column, index) => {
        this.updatedColsContacts.push(this.columns[index]);
        localStorage.setItem(
          "updatedColsContacts",
          // eslint-disable-next-line comma-dangle
          JSON.stringify(this.updatedColsContacts)
        );
      });
    },
  },
};
</script>

<style>
#contacts .right-header-col a {
  padding-right: 0;
}
#contacts .reset-deals {
  padding: 5px 10px;
  display: flex;
}
#contacts .reset-shipped {
  padding: 5px 10px;
  display: flex;
}
</style>
